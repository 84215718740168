// @import 'antd/lib/style/themes/default.less';
// @import 'antd/dist/antd.less';

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
@import url('https://cdn.jsdelivr.net/npm/quill@2/dist/quill.snow.css');

// The prefix to use on all css classes from ant.
// @ant-prefix: antcoach;

@tailwind base;
@tailwind components;
@tailwind utilities;

// TODO: remove tailwind and update ant/design version

svg {
	display: inline;
}

// An override for the html selector for theme prefixes
@html-selector: html;

// General
@primary-color: #6739b7;
@border-radius-base: 5px; // major border radius
@font-family: 'Inter', 'Helvetica Neue', Arial, Helvetica, sans-serif;
// @font-size-lg: @font-size-base + 1px;
@error-color: #ff01ba;
@text-color: #5e5e5e;

// buttons
@btn-border-width: 1.5px;
@btn-default-border: @primary-color;
@btn-default-color: @primary-color;
@btn-font-weight: 600;
@btn-height-lg: 42px;
// @btn-font-size-lg: @font-size-base;

@btn-green: #01c75245;

@input-placeholder-color: @text-color;

//custom css//
// button outline bg hover
.ant-btn:hover {
	background: @primary-color;
	// color: @component-background;
}

.ant-btn-lg,
.ant-btn-round.ant-btn-lg {
	padding: 7px 15px 9px;
}

.btn-solid-green.ant-btn[disabled] {
	background: @btn-green !important;
	border: 2px solid @btn-green !important;
	color: #ffffff45 !important;
}

.btn-solid-green {
	background: #01c752 !important;
	border: 2px solid #01c752 !important;
	color: #fff !important;
}

.bulk-invite-wrapper .ant-btn-lg{
	border-width: 1px;
}

.bulk-invite-table .ant-btn-lg{
	border-width: 1px;
}

.btn-solid-green:hover {
	border: 2px solid #31d373 !important;
	background: #31d373 !important;
}

.ant-btn-icon-only,
.ant-btn-lg {
	border-width: 3px;
}

//popover
.ant-popover-title {
	font-size: 14px;
	font-weight: 700;
}

//slider range
.ant-range-slider .ant-slider-mark-text {
	font-family: 'Inter', 'Helvetica Neue', Arial, Helvetica, sans-serif;
	font-weight: 400;
	font-size: 12px;
	color: @primary-color;
}

.ant-btn-primary:hover,
.ant-btn-primary:focus {
	border-color: transparent;
	background: darken(@primary-color, 10%);
}

.ant-btn-primary.ant-btn[disabled] {
	color: #ffffff45;
	border-color: #6739b745;
	background: #6739b745;
}

//modal
// --
@modal-header-title-font-size: 18px;

.ant-modal-title {
	color: @primary-color;
	font-weight: 700;
}

//custom btns for top player
.overlay-coach .btn-fullscreen.ant-btn,
.overlay-coach .btn-invert-cam.ant-btn {
	background: #6823c0;
	border-color: #6823c0;
}

.overlay-coach .btn-fullscreen.ant-btn svg,
.overlay-coach .btn-invert-cam.ant-btn svg {
	color: #fff;
}

.overlay-coach .btn-fullscreen.ant-btn:hover,
.overlay-coach .btn-invert-cam.ant-btn:hover {
	background: #fff;
	border-color: #fff;
}

.overlay-coach .btn-fullscreen.ant-btn svg:hover,
.overlay-coach .btn-invert-cam.ant-btn svg:hover {
	color: #6823c0;
}

.overlay-coach .btn-fullscreen.ant-btn,
.overlay-coach .btn-invert-cam.ant-btn {
	padding: 2.4px 0;
}

.overlay-coach .btn-fullscreen.ant-btn {
	position: absolute;
	top: 10px;
	right: 62px;
	z-index: 1006;
}

.overlay-coach .btn-invert-cam.ant-btn {
	position: absolute;
	top: 10px;
	right: 10px;
	z-index: 1006;
}

.btn-next.ant-btn,
.btn-prev.ant-btn {
	position: absolute;
	top: 50%;
	z-index: 1005;
}

.btn-prev.ant-btn {
	left: 10px;
}

.btn-next.ant-btn {
	right: 10px;
}

#root .btn-prev.ant-btn,
#root .btn-next.ant-btn {
	background: rgba(255, 255, 255, 0.1);
	border-color: transparent;
	padding: 10px 0;
	width: 56px;
	height: 56px;
	border-radius: 9999px;
	margin-top: -20px;
}

.btn-prev.ant-btn,
.btn-next.ant-btn {
	display: none;
}

.coach-container:hover .btn-prev.ant-btn,
.coach-container:hover .btn-next.ant-btn {
	display: block;
}

.btn-prev.ant-btn:hover,
.btn-next.ant-btn:hover {
	background: rgba(255, 255, 255, 0.4);
}

.btn-prev.ant-btn svg polyline,
.btn-next.ant-btn svg polyline {
	stroke: #fff;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
	text-shadow: none;
}

//pagination
.library-content li.ant-pagination-total-text {
	color: #fff;
}

.library-content .ant-pagination-item,
.library-content .ant-pagination-prev .ant-pagination-item-link,
.library-content .ant-pagination-next .ant-pagination-item-link,
.library-content
	.ant-select:not(.ant-select-customize-input)
	.ant-select-selector {
	background: rgba(255, 255, 255, 0.2);
	border: 1px solid transparent;
	color: #fff;
}

.library-content .ant-pagination-item.ant-pagination-item-active {
	font-weight: 500;
	background: rgba(255, 255, 255, 0.2);
	border-color: #fff;
}

.library-content .ant-pagination-item a {
	color: rgba(255, 255, 255, 0.5);
}

.library-content
	.library-content
	.ant-pagination-jump-prev
	.ant-pagination-item-container
	.ant-pagination-item-link-icon,
.ant-pagination-jump-next
	.ant-pagination-item-container
	.ant-pagination-item-link-icon {
	color: #fff;
}

.library-content .ant-pagination-prev .ant-pagination-item-link:disabled,
.library-content .ant-pagination-next .ant-pagination-item-link:disabled {
	background: rgba(255, 255, 255, 0.05);
}

.library-content .ant-pagination-prev:hover .ant-pagination-item-link,
.library-content .ant-pagination-next:hover .ant-pagination-item-link,
.library-content .ant-pagination-item:hover {
	color: #fff;
	border-color: #fff;
}

.ant-tooltip-inner{
	user-select: none;
}

.coach-container:hover .btn-prev.ant-btn,
.coach-container:hover .btn-next.ant-btn {
	display: block;
}

.coach-container .btn-prev.ant-btn,
.coach-container .btn-next.ant-btn {
	display: none;
}

.coach-container .btn-next.ant-btn {
	right: 10px;
}

.coach-container .btn-next.ant-btn,
.coach-container .btn-prev.ant-btn {
	position: absolute;
	top: 50%;
	z-index: 1005;
}

.coach-container .btn-prev.ant-btn:hover,
.coach-container .btn-next.ant-btn:hover {
	background: rgba(255, 255, 255, 0.4);
}

.coach-container .ant-btn:not([disabled]):hover {
	text-decoration: none;
}

.coach-container .btn-prev.ant-btn,
.coach-container .btn-next.ant-btn {
	background: rgba(255, 255, 255, 0.1);
	border-color: transparent;
	padding: 10px 0;
	width: 56px;
	height: 56px;
	margin-top: -20px;
}
.ant-pagination-next button::before {
	content: 'Next';
}
.ant-pagination-next button span.anticon,
.ant-pagination-prev button span.anticon {
	display: none;
}
.ant-pagination-prev button::after {
	content: 'Previous';
}
li.ant-pagination-item,
.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
	height: auto;
	padding: 5px 10px;
	font-size: 14px;
	font-weight: bold;
}
.ant-pagination-prev,
.ant-pagination-next {
	height: auto;
}
.ant-pagination-item-active {
	background: #eaecf0;
	border-color: #eaecf0;
}
.ant-pagination {
	margin: 20px 0;
	text-align: center;
}

.ant-upload-wrapper .ant-upload-drag{
	background-color: inherit;
}

@-webkit-keyframes BgAnimaiton {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}
@-moz-keyframes BgAnimaiton {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}
@keyframes BgAnimaiton {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

.select-none {
	user-select: none;
}
