.weekly-container{
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}

.weekly-div{
  display: flex;
  margin-left: 0px;
  cursor: pointer;
  --tw-text-opacity: 1;
  color: rgb(105 65 198 / var(--tw-text-opacity));
  font-weight: 700;
}

.weekly-icon{
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-right: 1.75rem;
}

.custom-w-15{
  width: 15rem;
}

.custom-w-3{
  width: 3.5rem;
}

.weekly-today{
  cursor: pointer;
  --tw-text-opacity: 1;
  color: rgb(105 65 198 / var(--tw-text-opacity));
  font-weight: 700;
}

.weekly-main-container{
  display: flex;
  text-align: center;
  margin-left: -1rem;
}

.custom-weekly-css{
  display: block;
  text-align: center;
  font-size: 1rem;
  width: 2.5rem;
  height: 2.5rem;
  line-height: 2.5rem;
  border-radius: 50%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0;
  margin-bottom: 0;
}

.custom-week-date-css{
  --tw-text-opacity: 1;
  color: rgb(105 65 198 / var(--tw-text-opacity));
  cursor: pointer;
}

.custom-week-date-gray-css{
  --tw-text-opacity: 1;
  color: rgb(102 112 133 / var(--tw-text-opacity));
  cursor: pointer;
}

.custom-w-full{
  width:100%
}

.custom-block-text-base {
  display: block;
  font-size: 0.65;
  line-height: 1.5rem;
  font-weight: 600;
}

.custom-class-css-week {
  display: block;
  text-align: center;
  font-size: 1rem;
  width: 2.5rem; 
  height: 2.5rem;
  line-height: 2.5rem;
  border-radius: 50%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0;
  margin-bottom: 0;
}

.custom-css-current-date{
  --tw-bg-opacity: 1;
  background-color: rgb(105 65 198 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  cursor: pointer;
}

.custom-css-event-current-date{
  --tw-bg-opacity: 1;
  background-color: rgb(214 187 251 / var(--tw-bg-opacity));
  cursor: pointer;
}

.custom-bg-primary-week{
  --tw-bg-opacity: 1;
  background-color: rgb(105 65 198 / var(--tw-bg-opacity))!important;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity))!important;
  cursor: pointer;
}

.custom-bg-gray-week{
  --tw-bg-opacity: 1;
  background-color: rgb(242 244 247 / var(--tw-bg-opacity));
  cursor: pointer;
}