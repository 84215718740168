.add-notes-container {
	margin-top: 0.75rem;
	margin-bottom: 1.5rem;
}
.add-notes-label {
	margin-bottom: 0.5rem;
}
.popover-modal {
	display: flex;
	justify-content: center;
	align-items: center;
}

.notes-save-button {
	float: right;
	padding-top: 0.25rem;
	padding-bottom: 0.25rem;
	padding-left: 0.75rem;
	padding-right: 0.75rem;
	margin-top: 0.5rem;
	border-radius: 0.375rem;
	color: #ffffff;
	cursor: pointer;
	background-color: #7f56d9;
}
