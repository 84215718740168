.scrollable-calendar{
  margin-left: -1.5rem;
  width: 425px;
  overflow: auto;
  position: relative;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  z-index: 40;
  height: 482px;
  padding-left: 1.25rem;
}
.scrollable-calendar-container{
  position: absolute;
  padding-right: 0.5rem;
}
.scrollable-calendar-monthly-year{
  --tw-text-opacity: 1;
  color: rgb(105 65 198 / var(--tw-text-opacity));
  font-weight: 700;
  margin-top: 2rem;
}
.scrollable-calendar-days{
  display: block;
  text-align: center;
  font-size: 1rem;
  line-height: 1.5rem;
  padding-bottom: 0.75rem;
}