.activity-stream-footer {
  display: flex;
  align-items: start;
  justify-content: start;
  user-select: none;
}

.activity-stream-footer .fixed-footer {
  position: fixed;
  bottom: 0;
  padding: 0.25rem;
  margin-right: 1rem;
  height: max-content;
  background-color: #f3f4f6;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: -2rem;
  width: 80vw;
  z-index: 99;
}

.activity-stream-footer .reply-item {
  padding: 0;
  height: 100px;
  border-radius: 0.75rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: start;
  margin-bottom: 0.5rem;
  cursor: pointer;
  background: rgba(213, 217, 235, 1);
  width: 75%;
  overflow: hidden;
}

.activity-stream-footer .reply-item-header {
  margin-left: 0;
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 0.5rem;
}

.activity-stream-footer .reply-item-header .profile-image {
  width: 20px;
  height: 20px;
  margin-right: 0.5rem;
  border-radius: 50%;
  object-fit: cover;
}

.activity-stream-footer .reply-item-body {
  margin-right: 0.5rem;
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
}

.activity-stream-footer .reply-item-content {
  display: flex;
  flex-direction: column;
  margin-right: 0.5rem;
}

.activity-stream-footer .footer-controls {
  width: 83.333333%;
  padding: 0.5rem;
  border-radius: 0.375rem;
  display: flex;
  align-items: center;
}

.activity-stream-footer .lottie-animation {
  height: 50px;
  width: 75px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.popup-item {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  align-items: center;
  margin-bottom: 5px;
  cursor: pointer;
  user-select: none;
}

.activity-stream-footer .textarea {
  border-radius: 0.375rem;
  padding: 0.25rem;
  width: 100%;
  background-color: #ffffff;
  padding-left: 0.5rem;
  gap: 2rem;
  outline: none;
  resize: none;
}

.custom-space-footer {
  height: 50px;
  padding: 5px;
  background: rgba(105, 65, 198, 1);
}

.custom-footer-username {
  --tw-text-opacity: 1;
  color: rgb(78 91 166 / var(--tw-text-opacity));
  font-weight: 700;
}

.custom-text-gray-blue-500 {
  --tw-text-opacity: 1;
  color: rgb(78 91 166 / var(--tw-text-opacity));
}

.custom-close {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-right: 1.25rem;
  height: 100px;
}

.custom-icon-text {
  margin-left: 0.25rem;
  margin-top: 0.5rem;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.custom-icon-class {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  cursor: pointer;
}

.custom-x-icon-class {
  --tw-bg-opacity: 1;
  background-color: rgb(208 213 221 / var(--tw-bg-opacity));
  border-radius: 9999px;
  padding: 0.25rem;
  align-items: center;
  justify-content: center;
  display: flex;
}

.custom-stop-icon-class {
  margin-right: 0.5rem;
  cursor: pointer;
}

.custom-ml-2 {
  margin-left: 0.5rem;
}

.custom-pointer-events-none {
  pointer-events: none;
}

.custom-pointer-events-auto {
  pointer-events: auto;
}
