.splash {
	background: rgb(166, 126, 255);
	background: linear-gradient(
		130deg,
		rgb(100 53 205 / 95%) 0%,
		rgb(87 94 189 / 95%) 100%
	);
	position: absolute;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	opacity: 1;
	top: 0;
}
.orom-splash-wrapper .splash .container {
	width: 100%;
	text-align: center;
	height: 100%;
}
h2.splash-subtitle {
	font-weight: 700;
	font-size: 25px;
	font-family: 'Inter', 'Helvetica Neue', Arial, Helvetica, sans-serif;
	color: #fff;
	margin-top: 0;
}
.splash-actions .btn-outline {
	margin: 0 auto 20px;
}
.splash-actions {
	margin: 0 0 30px;
}
.splash-actions button.btn-outline:first-child {
	padding: 8px 42px;
	display: block;
}

.orom-splash-wrapper .title-top {
	position: absolute;
	/* top: 80px; */
	left: 50px;
}
.orom-splash-wrapper .title-top h3 {
	color: #fff;
	font-size: 40px;
	font-weight: bold;
}
.orom-splash-wrapper .body-points {
	height: 100%;
}
.orom-splash-wrapper .body-points button.ant-btn {
	position: absolute;
	height: auto;
	background: none !important;
	opacity: 0.6;
	border: 2px solid #fff !important;
	/* padding: 8px 24px; */
}
.orom-splash-wrapper .body-points button.ant-btn:hover {
	opacity: 0.9;
}
.orom-splash-wrapper .hotspot-circle.active {
	background: #01c752;
	border: 2px solid #fff;
	cursor: default;
}
.orom-splash-wrapper .hotspot-circle.active:after {
	display: none;
}
.orom-splash-wrapper .hotspot-circle:hover {
	background: rgba(255, 255, 255, 0.5);
}

.hotspot-circle {
	width: 20px;
	height: 20px;
	background: rgba(255, 255, 255, 0.5);
	border-radius: 50%;
	position: absolute;
	cursor: pointer;
}
