.custom-fixed {
	position: fixed;
	background-color: #ffffff;
	width: -webkit-fill-available;
	z-index: 10;
	margin-right: 1.25rem;
}

.custom-container {
	user-select: none;
	border: inherit;
	border-radius: 0.375rem;
	border-width: 1px;
	margin: 20px;
}

.relative-index {
	position: relative;
	z-index: 10;
}

.flexCenter {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: row;
	gap: 0.5rem;
}

.flexItemCenter {
	display: flex;
	align-items: center;
	justify-content: center;
}

.fullscreen {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 80vh;
	width: 80vw;
}

.custom-empty-container {
	display: flex;
	margin-top: 1.25rem;
	align-items: center;
	justify-content: center;
	height: 80vh;
}

.flex-row-items-center {
	display: flex;
	flex-direction: row;
	align-items: center;
}

.custom-share-icon {
	cursor: pointer;
	margin-left: 0.5rem;
}

.custom-heading {
	cursor: pointer;
	font-size: 1.25rem;
	font-weight: bold;
}

.custom-styles {
	cursor: pointer;
	font-size: 16px;
	font-weight: normal;
	color: #a0aec0;
}

.custom-layout {
	position: relative;
	margin-top: -4rem;
	display: flex;
	justify-content: flex-end;
}
.custom-header-inherit {
	height: 118px;
	padding-left: 1.5rem;
	border-bottom-width: 2px;
	border-color: inherit;
	position: fixed;
	z-index: 50;
	margin-top: -20px;
	width: -webkit-fill-available;
	background-color: #ffffff;
}

.custom-activity-report-container {
	display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  position: fixed;
  padding-right: 0.5rem;
  z-index: 99;
  margin-top: 7rem;
  width: 20%;
  right: 0;
}

.custom-activity-report-inner-container {
	display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  cursor: pointer;
}

.custom-container-item {
	padding: 1rem;
	margin-top: 5.5rem;
	margin-left: 1.25rem;
}

.custom-container-header {
	padding: 1rem 1rem 5rem 1rem;
	margin-top: 14.5rem;
}

.custom-mb-6rem {
	margin-bottom: 6rem;
}

.custom-mb-0 {
	margin-bottom: 0;
}

.custom-arrow-icon {
	--tw-bg-opacity: 1;
	margin-bottom: 0.5rem;
	cursor: pointer;
	padding: 0.25rem;
	border-radius: 9999px;
	background-color: rgb(105 65 198 / var(--tw-bg-opacity));
}

.itemCenter {
	align-items: center;
}

.padding {
	padding: 0.25rem;
}

.custom-activity-id {
	text-align: center;
	margin-right: 0.75rem;
}

.custom-date {
	font-weight: 700;
	--tw-text-opacity: 1;
	color: rgb(102 112 133 / var(--tw-text-opacity));
}

.custom-header{
	position: fixed;
	--tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
	width: -webkit-fill-available;
	z-index: 10;
	margin-right: 1.25rem;
}

.custom-inline-flex {
	color: #FFF;
	font-size: 24px;
	display: inline-flex;
	align-items: center;
}

.custom-activity-stream{
	overflow-x: hidden;
}
