.bulk-invite-container {
	display: flex;
	margin-top: 1.25rem;
	flex-direction: column;
	gap: 1.25rem;

	.bulk-invite-user-info {
		padding-bottom: 0.75rem;
		margin-bottom: 0.5rem;
		border-bottom-width: 2px;
		border-color: inherit;
	}

	.bulk-invite-data-manipulator {
		display: grid;
		grid-template-columns: repeat(4, minmax(0, 1fr));
		gap: 0.5rem;

		.add-admin {
			display: flex;
			grid-column: span 1 / span 1;
			gap: 0.5rem;
			cursor: pointer;
			justify-content: center;
			align-items: center;
			border-radius: 0.5rem;
			border-width: 1px;
			border-color: #d1d5db;
		}
	}
}

