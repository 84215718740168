.modal-container svg {
	display: block;
}

.form-div {
	text-align: center;
	width: 474px;
	display: inline-block;
}

.form-container {
	background: #eaecf0;
	padding-left: 20px;
	padding-right: 20px;
	padding-top: 20px;
	padding-bottom: 1px;
	margin-top: 20px;
	border-radius: 6px;
	gap: 20px;
}

.custom-select-class .ant-select-selector {
	min-height: 2.5rem;
}
.profile-heading {
	font-weight: 600;
	font-size: 1.125rem;
	text-align: center;
	margin-bottom: 0.5rem;
	line-height: 1.75rem;
}

.profile-div {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 0.5rem;
}
.input-item {
	height: 2.75rem;
}

.select-field-input-item .ant-select-selector{
	min-height: 2.75rem;
}

.input-w-20{
	width: 20%;
}

.input-w-80{
	width: 80%;
}

.input-w-50{
	width: 50%;
}

.input-w-full{
	width: 100%;
}

.input-flex-gap{
	display: flex;
	gap:1.25rem;
	width: 100%;
}

.input-form-label {
	display: inline-block;
	color: #ef4444;
	font-size: 0.875rem;
	line-height: 1rem;
	font-family: 'Simsun';
}
.checkbox-form {
	margin-left: 0.5rem;
	color: rgb(127 86 217 / var(--tw-text-opacity));
	font-size: 0.875rem;
	line-height: 1.25rem;
}
.consent-form-title {
	font-weight: 600;
	cursor: pointer;
}
.back-profile {
	color: #6941c6;
	font-weight: 600;
	font-size: 0.875rem;
	line-height: 1.25rem;
}
.consent-form-div {
	width: 100%;
	min-width: 640px;
	padding: 1.25rem;
	color: rgb(71 84 103 / var(--tw-text-opacity));
	user-select: none;
}
.complete-profile {
	font-size: 1rem;
	font-weight: 700;
	padding-bottom: 0.5rem;
}
.consent-para {
	padding-bottom: 0.5rem;
	font-weight: 400;
	font-size: 0.875rem;
	line-height: 1.25rem;
}
