@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;700&display=swap');

html,
#demo,
.App {
	height: 100%;
}

body {
	margin: 0;
	height: 100%;
}

#demo {
	background: rgb(166, 126, 255);
	background: linear-gradient(
		130deg,
		rgba(166, 126, 255, 1) 0%,
		rgba(89, 101, 255, 1) 100%
	);
}

.btn-solid-green {
	border-radius: 40px !important;
	padding: 7px 42px;
	background: #01c752;
	border: 2px solid #01c752;
	color: #fff;
	text-transform: uppercase;
	cursor: pointer;
	font-weight: 700;
	font-size: 14px;
	font-family: 'Inter', 'Helvetica Neue', Arial, Helvetica, sans-serif;
}

button.btn-solid-green:hover {
	border: 2px solid #31d373;
	background: #31d373;
}

.btn-outline {
	border-radius: 40px;
	padding: 8px 42px;
	background: transparent;
	border: 2px solid #ffffff;
	color: #fff;
	text-transform: uppercase;
	cursor: pointer;
}

button.btn-outline:hover {
	background: #6823c0;
	border-color: #6823c0;
}

.btn-solid-purpple {
	border-radius: 7px;
	padding: 8px;
	background: #6823c0;
	border: 2px solid #6823c0;
	color: #fff;
	text-transform: uppercase;
	cursor: pointer;
}

button.btn-solid-purpple:hover {
	background: #9547fc;
	border-color: #9547fc;
	color: #fff;
}

canvas {
	width: 100%;
}

.coach-container {
	position: relative;
	max-width: 1280px;
	max-height: 720px;
	width: 100%;
	height: 100%;
	margin: 0 auto;
}

.overlay-container {
	position: absolute;
	top: 0;
	width: 100%;
	height: 100%;
}

.main-video {
	width: 100%;
}

canvas.overlay-canvas {
	width: 100%;
	height: 100%;
}

.overlay-coach {
	width: 100%;
	height: 100%;
	position: relative;
	top: 0;
	overflow: hidden;
}

.overlay-top {
	background: rgb(0, 0, 0);
	background: linear-gradient(
		180deg,
		rgba(0, 0, 0, 0.7) 0%,
		rgba(0, 0, 0, 0) 100%
	);
	z-index: 101;
	position: absolute;
	height: 85px;
}

.overlay-bottom {
	background: rgb(0, 0, 0);
	background: linear-gradient(
		180deg,
		rgba(0, 0, 0, 0) 0%,
		rgba(0, 0, 0, 0.7) 100%
	);
	z-index: 101;
	position: absolute;
	width: 100%;

	position: absolute;
	bottom: 0;
	text-align: right;
}

.overlay-top > svg {
	width: 100%;
	display: block;
	height: 85px;
	margin: 0 auto;
}

.overlay-bottom svg {
	width: 98%;
	margin-bottom: 20px;
}

h1.exercise-desc {
	position: absolute;
	top: 0;
	left: 37%;
	color: #fff;
	font-size: 20px;
	margin-top: 20px;
	font-family: 'Inter', 'Helvetica Neue', Arial, Helvetica, sans-serif;
	font-weight: 400;
}

h2.exercise-result-range {
	position: absolute;
	top: 220px;
	right: 8%;
	color: #fff;
	font-size: 25px;
	margin-top: 20px;
	font-family: 'Inter', 'Helvetica Neue', Arial, Helvetica, sans-serif;
	font-weight: 300;
	font-size: 60px;
}

h2.exercise-result-range:before {
	content: '';
}

.rom-container .overlay-content {
	width: 40%;
	position: absolute;
	top: 90px;
	left: 0;
	z-index: 101;
}

.rom-container .instruction-actions .ant-btn {
	background: none;
}

.instruction-video {
	width: 100%;
	position: absolute;
}

@media screen and (max-width: 1024px) {
	h1.exercise-desc {
		font-size: 15px;
	}
}

@media screen and (max-width: 768px) {
	h1.exercise-desc {
		font-size: 13px;
		margin-top: 15px;
	}

	.overlay-content {
		bottom: 47px;
	}
}

@media screen and (max-width: 480px) {
	h1.exercise-desc {
		font-size: 11px;
	}

	.overlay-top svg {
		height: 65px;
	}

	.overlay-content {
		bottom: 47px;
	}
}

.overlay-bottom circle {
	cx: 107;
}

.estimator-template {
	background: inherit;
	margin: 0 auto;
}

.btn-outline.replay {
	position: absolute;
	z-index: 105;
	top: 55px;
	left: 25px;
}

img.icon-play {
	width: 20px;
	vertical-align: middle;
	margin-right: 5px;
}

button.btn-icon.btn-menu {
	position: absolute;
	border: none;
	z-index: 101;
}

img.icon-menu {
	width: 20px;
	vertical-align: middle;
	margin-right: 5px;
}

button.btn-icon.btn-menu:hover i.material-icons {
	color: rgba(255, 255, 255, 0.5) !important;
}

.main-menu {
	background: #642cb9e3;
	width: 100%;
	max-width: 430px;
	height: 100%;
	z-index: 1006;
	position: absolute;
	left: -430px;
	transition: 850ms;
	overflow-y: auto;
}

.main-menu.active {
	transition: 550ms;
	left: 0;
	top: 0;
}

.overlay-menu {
	width: 100%;
	height: 100%;
	background: #000000a1;
	position: relative;
	z-index: 1004;
	overflow: hidden;
	display: none;
}

.overlay-menu.active {
	display: block;
}

ul.exercise-list {
	list-style: none;
	padding: 0 0 0 0;
}

ul.exercise-list li {
	font-size: 14px;
	font-weight: 400;
	color: rgba(255, 255, 255, 0.5);
	font-family: 'Inter', 'Helvetica Neue', Arial, Helvetica, sans-serif;
	text-transform: uppercase;
	border-bottom: 1px solid rgba(255, 255, 255, 0.2);
	cursor: pointer;
	padding: 10px 20px 10px 40px;
	display: flex;
	justify-content: space-between;
	line-height: 26px;
}

.exercise-list .md-icon {
	vertical-align: middle;
	padding-right: 6px;
}

.btn-menu svg.md-icon {
	fill: #fff;
}

svg.exercise-check.md-icon {
	fill: rgba(255, 255, 255, 0.5);
}

@media only screen and (min-width: 1200px) {
	ul.exercise-list li svg.md-icon.compare-icon {
		display: none;
	}

	ul.exercise-list li:hover svg.md-icon.compare-icon {
		display: initial;
	}
}

svg.md-icon:hover {
	fill: rgba(255, 255, 255, 0.5);
}

ul.exercise-list li.primary-links {
	display: block;
	color: #fff;
}

ul.exercise-list .menu-subtitle {
	font-weight: 600;
	cursor: default;
	margin-top: 25px;
	color: rgba(255, 255, 255, 0.5);
}

ul.exercise-list .menu-subtitle:hover {
	background: transparent;
}

ul.exercise-list li:hover {
	background: rgba(255, 255, 255, 0.07);
}

ul.exercise-list li.done {
	color: #fff;
}

.bar-container {
	background: rgba(255, 255, 255, 0.2);
	width: 100%;
	height: 5px;
}

a.btn-play-pause {
	position: absolute;
	right: 25px;
	bottom: 70px;
	color: #fff;
	z-index: 140;
}

.rom .coach-container.fullscreen,
.rom .estimator-template.fullscreen {
	background: inherit;
	max-width: 100%;
	max-height: 100%;
}

.rom .fullscreen .videoRecord {
	max-width: 100%;
	max-height: 100%;
}

.rom .fullscreen .main-video {
	max-width: 100%;
	width: 100%;
	height: 100%;
}

.progress-bar {
	position: relative;
	z-index: 101;
	color: #fff;
	top: 6px;
	left: 34px;
}

.area-highlight {
	background: #fff;
	width: 200px;
	height: 200px;
	position: absolute;
	z-index: 9999;
	opacity: 0.1;
	border-radius: 1000px;
	left: 25px;
	top: 195px;
}

.area-highlight.upper {
	top: 20px;
}

.area-highlight.upper.right {
	left: 107px;
	top: 60px;
	width: 150px;
	height: 150px;
}

.area-highlight.upper.left {
	left: -16px;
	top: 60px;
	width: 150px;
	height: 150px;
}

.area-highlight.full {
	left: -50px;
	top: 56px;
	width: 350px;
	height: 350px;
}

.instructional-active {
	position: absolute;
	z-index: 111;
	width: 40%;
	top: 30px;
}

.main-action {
	position: absolute;
	background: #5e59c1;
	bottom: 6px;
	right: 0;
}

.main-action button.ant-btn {
	background: none;
	color: #fff;
}

.single-result .rom-header .btn-submit-record,
.single-result .rom-header .btn-delete-record {
	position: relative;
	bottom: unset;
	right: unset;
	vertical-align: top;
}

.upload-progress-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	color: #ffffff;
	padding: 20px;
}

.upload-message {
	font-size: 1.5em;
	margin-bottom: 20px;
	color: #ffffff;
	text-align: center;
}

.upload-progress-bar .ant-progress-bg {
	border-radius: 6px;
	text-align: center;
	transition: width 0.2s ease;
}

.upload-progress-bar .ant-progress-outer {
	width: 100%;
	max-width: 500px;
}

.progress-percentage {
	font-size: 1.2em;
	color: #ffffff;
	margin-top: 10px;
	font-weight: bold;
	transition: color 0.4s ease;
}

.progress-percentage::after {
	content: ' completed';
	font-size: 0.8em;
	color: #ffffff;
}
