.add-survey-popup {
	.modal-title {
		display: flex;
		gap: 16px;
	}

	.empty {
		margin-top: 16px;
	}

	.empty-image {
		background-color: rgb(249 250 251);
		padding: 40px;
		margin: 0px;
	}

	.empty-image-text {
		color: rgb(208 213 221);
	}

	.template-button-container {
		display: flex;
		align-items: center;
		justify-content: end;
		gap: 1rem;
		background-color: #f9f5ff;
		color: #6941c6;
		padding: 2px 8px;
		border-radius: 16px;
		width: max-content;
	}

	.template-checkbox {
		display: flex;
		gap: 0.5rem;
		justify-content: center;
		align-items: center;
		margin-bottom: 8px;
	}

	.save-survey-button {
		display: flex;
		padding: 0.5rem;
		margin-top: 0.5rem;
		margin-bottom: 0.5rem;
		gap: 0.5rem;
		justify-content: center;
		align-items: center;
		border-radius: 0.5rem;
		width: 100%;
		font-weight: 600;
		color: #ffffff;
		background-color: #6739b7;
		cursor: pointer;
	}
}

.create-survey-modal {
	.modal-title {
		display: flex;
		gap: 16px;
	}
}

.my-survey-modal-data {
	.search {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.search-input {
		margin-top: 0.75rem;
		margin-bottom: 0.75rem;
		height: 2.5rem;
		width: 82%;
	}

	.survey-button {
		white-space: nowrap;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.card-style {
		display: flex;
		margin-bottom: 0.75rem;
		gap: 0.5rem;
		justify-content: flex-end;
		align-items: center;
	}

	.card-style-div {
		display: flex;
		justify-content: flex-end;
	}

	.button-style-grid-true {
		padding: 0.25rem;
		border-top-right-radius: 0.5rem;
		border-bottom-right-radius: 0.5rem;
		border-width: 1px;
		border-left-width: 0;
		border-color: #d1d5db;
		text-align: center;
		cursor: pointer;
	}

	.button-style-grid-false {
		padding: 0.25rem;
		border-top-left-radius: 0.5rem;
		border-bottom-left-radius: 0.5rem;
		border-width: 1px;
		border-color: #d1d5db;
		text-align: center;
		cursor: pointer;
	}

	.spinner {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.card-style-grid-false {
		display: flex;
		padding: 1rem;
		gap: 1rem;
		border-radius: 0.5rem;
		border-width: 4px;
		width: 100%;
		cursor: pointer;

		.image {
			display: flex;
			width: 10rem;
			padding: 8px 0px 8px 8px;
		}

		.image-not-found {
			border-top-left-radius: 0.5rem;
			border-bottom-left-radius: 0.5rem;
			width: 100%;
			height: 9rem;
			border-radius: 0.5rem;
		}

		.card-content {
			padding: 0.5rem;
			flex: 1 1 0%;
			border-radius: 0.5rem;
		}

		.card-content-header {
			display: flex;
			justify-content: space-between;
			align-items: center;
		}

		.card-title {
			font-size: 1.125rem;
			line-height: 1.75rem;
			font-weight: 600;
			color: #6b7280;
		}

		.question-count {
			font-size: 0.75rem;
			line-height: 1rem;
			font-weight: 600;
			color: #9ca3af;
		}

		.card-description {
			margin-top: 0.5rem;
			color: #6b7280;
		}

		.select-button {
			display: flex;
			padding-top: 0.5rem;
			padding-bottom: 0.5rem;
			padding-left: 0.5rem;
			padding-right: 0.5rem;
			gap: 0.5rem;
			justify-content: center;
			align-items: center;
			border-radius: 0.5rem;
		}
	}

	.card-style-grid-true {
		display: flex;
		padding: 0.5rem;
		gap: 1rem;
		justify-content: center;
		align-items: center;
		border-radius: 1rem;
		border-width: 4px;
		width: 100%;
		cursor: pointer;

		.ant-image .image {
			flex-shrink: 0;
			width: 3.5rem;
			padding: 8px 0px 8px 8px;
		}

		.image-not-found {
			border-top-left-radius: 0.5rem;
			border-bottom-left-radius: 0.5rem;
			width: 5rem;
			height: 5rem;
			border-radius: 0.5rem;
		}

		.card-content {
			padding: 0.5rem;
			flex: 1 1 0%;
			border-radius: 1rem;
		}

		.card-title {
			font-size: 1rem;
			line-height: 1.25rem;
			display: -webkit-box;
			-webkit-line-clamp: 1;
			-webkit-box-orient: vertical;
			overflow: hidden;
			font-weight: 600;
			color: #6b7280;
		}

		.question-count-container {
			display: flex;
			gap: 0.75rem;
			justify-content: flex-start;
			font-size: 0.875rem;
			line-height: 1.25rem;
			font-weight: 600;
			color: #9ca3af;
		}

		.question-count {
			font-size: 0.75rem;
			line-height: 1rem;
			font-weight: 600;
			color: #9ca3af;
		}

		.select-button {
			display: flex;
			padding-top: 0.25rem;
			padding-bottom: 0.25rem;
			padding-left: 0.25rem;
			padding-right: 0.25rem;
			gap: 0.25rem;
			justify-content: center;
			align-items: center;
			border-radius: 0.5rem;
		}
	}

	.empty {
		padding: 2.5rem;
		margin: 0;
		background-color: #f9fafb;
	}

	.pagination {
		display: flex;
		justify-content: center;
	}
}

.my-template-survey {
	.search-input {
		margin-top: 0.75rem;
		margin-bottom: 0.75rem;
		width: 100%;
		height: 2.5rem;
	}

	.card-style {
		display: flex;
		margin-bottom: 0.75rem;
		gap: 0.5rem;
		justify-content: flex-end;
		align-items: center;
	}

	.card-style-div {
		display: flex;
		justify-content: flex-end;
	}

	.button-style-grid-true {
		padding: 0.25rem;
		border-top-right-radius: 0.5rem;
		border-bottom-right-radius: 0.5rem;
		border-width: 1px;
		border-left-width: 0;
		border-color: #d1d5db;
		text-align: center;
		cursor: pointer;
	}

	.button-style-grid-false {
		padding: 0.25rem;
		border-top-left-radius: 0.5rem;
		border-bottom-left-radius: 0.5rem;
		border-width: 1px;
		border-color: #d1d5db;
		text-align: center;
		cursor: pointer;
	}

	.card-style-grid-false {
		display: flex;
		padding: 1rem;
		gap: 1rem;
		border-radius: 0.5rem;
		border-width: 4px;
		width: 100%;
		cursor: pointer;

		.image {
			display: flex;
			padding: 0.5rem;
			width: 10rem;
			padding: 8px 0px 8px 8px;
		}

		.ant-image .image-not-found {
			border-top-left-radius: 0.5rem;
			border-bottom-left-radius: 0.5rem;
			width: 100%;
			border-radius: 0.5rem;
		}

		.card-content {
			padding: 0.5rem;
			flex: 1 1 0%;
			border-radius: 0.5rem;
		}

		.card-content-header {
			display: flex;
			justify-content: center;
			align-items: center;
		}

		.card-title {
			font-size: 1.125rem;
			line-height: 1.75rem;
			font-weight: 600;
			color: #6b7280;
		}

		.question-count {
			font-size: 0.75rem;
			line-height: 1rem;
			font-weight: 600;
			color: #9ca3af;
		}

		.card-description {
			margin-top: 0.5rem;
			color: #6b7280;
		}

		.select-button {
			display: flex;
			padding-top: 0.5rem;
			padding-bottom: 0.5rem;
			padding-left: 0.5rem;
			padding-right: 0.5rem;
			gap: 0.5rem;
			justify-content: center;
			align-items: center;
			border-radius: 0.5rem;
		}
	}

	.card-style-grid-true {
		display: flex;
		padding: 0.5rem;
		gap: 1rem;
		justify-content: center;
		align-items: center;
		border-radius: 1rem;
		border-width: 4px;
		width: 100%;
		cursor: pointer;

		.ant-image .image {
			flex-shrink: 0;
			width: 3.5rem;
			padding: 8px 0px 8px 8px;
		}

		.image-not-found {
			border-top-left-radius: 0.5rem;
			border-bottom-left-radius: 0.5rem;
			width: 5rem;
			height: 5rem;
			border-radius: 0.5rem;
		}

		.card-content {
			padding: 0.5rem;
			flex: 1 1 0%;
			border-radius: 1rem;
		}

		.card-title {
			font-size: 1rem;
			line-height: 1.25rem;
			display: -webkit-box;
			-webkit-line-clamp: 1;
			-webkit-box-orient: vertical;
			overflow: hidden;
			font-weight: 600;
			color: #6b7280;
		}

		.question-count-container {
			display: flex;
			gap: 0.75rem;
			justify-content: flex-start;
			font-size: 0.875rem;
			line-height: 1.25rem;
			font-weight: 600;
			color: #9ca3af;
		}

		.question-count {
			font-size: 0.75rem;
			line-height: 1rem;
			font-weight: 600;
			color: #9ca3af;
		}

		.select-button {
			display: flex;
			padding-top: 0.25rem;
			padding-bottom: 0.25rem;
			padding-left: 0.25rem;
			padding-right: 0.25rem;
			gap: 0.25rem;
			justify-content: center;
			align-items: center;
			border-radius: 0.5rem;
		}
	}

	.empty {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 10rem;
	}

	.pagination {
		display: flex;
		justify-content: center;
	}
}

.selected-survey {
	.container {
		display: flex;
		margin-top: 0.5rem;
		justify-content: flex-start;
		align-items: flex-start;
	}

	.magic-wand {
		padding-top: 0.25rem;
		padding-bottom: 0.25rem;
		padding-left: 0.375rem;
		padding-right: 0.375rem;
		border-radius: 9999px;
	}

	.header-container {
		width: 100%;

		.header {
			font-weight: 700;
		}

		.header-title {
			color: rgb(102 112 133);
		}

		.header-description {
			color: rgb(102 112 133);
		}
	}
}

.suggested-survey {
	.tabs {
		padding: 1rem;
		background-color: #f3f4f6;
		user-select: none;
	}

	.survey-modal-data-container {
		padding: 1rem;
		border-radius: 0;
		background-color: #f9fafb;
	}

	.add-button {
		display: flex;
		padding: 0.5rem;
		gap: 0.5rem;
		justify-content: center;
		align-items: center;
		border-radius: 0.5rem;
		width: 100%;
		font-weight: 600;
		color: #ffffff;
		cursor: pointer;
	}
}

.suggested-survey-modal {
	.search-input {
		margin-top: 0.75rem;
		margin-bottom: 0.75rem;
		width: 100%;
		height: 2.5rem;
	}

	.card-style {
		display: flex;
		margin-bottom: 0.75rem;
		gap: 0.5rem;
		justify-content: flex-end;
		align-items: center;
	}

	.card-style-div {
		display: flex;
		justify-content: flex-end;
	}

	.question-length {
		padding-top: 0.125rem;
		padding-bottom: 0.125rem;
		padding-left: 0.5rem;
		padding-right: 0.5rem;
		border-radius: 9999px;
		color: #ffffff;
	}

	.button-style-grid-true {
		padding: 0.25rem;
		border-top-right-radius: 0.5rem;
		border-bottom-right-radius: 0.5rem;
		border-width: 1px;
		border-left-width: 0;
		border-color: #d1d5db;
		text-align: center;
		cursor: pointer;
	}

	.button-style-grid-false {
		padding: 0.25rem;
		border-top-left-radius: 0.5rem;
		border-bottom-left-radius: 0.5rem;
		border-width: 1px;
		border-color: #d1d5db;
		text-align: center;
		cursor: pointer;
	}

	.card-style-grid-false {
		display: flex;
		padding: 1rem;
		gap: 1rem;
		border-radius: 0.5rem;
		border-width: 4px;
		width: 100%;
		cursor: pointer;

		.video-container {
			flex-shrink: 0;
			width: 10rem;
		}

		.video {
			border-radius: 0.5rem;
			width: 100%;
			height: 100%;
		}

		.card-content {
			padding: 0.5rem;
			flex: 1 1 0%;
			border-radius: 0.5rem;
		}

		.card-title {
			font-size: 1.125rem;
			line-height: 1.75rem;
			font-weight: 600;
			color: #6b7280;
		}

		.question-count {
			font-size: 0.75rem;
			line-height: 1rem;
			font-weight: 600;
			color: #9ca3af;
		}

		.card-description {
			margin-top: 0.5rem;
			color: #6b7280;
		}

		.select-button {
			display: flex;
			padding-top: 0.5rem;
			padding-bottom: 0.5rem;
			padding-left: 0.5rem;
			padding-right: 0.5rem;
			gap: 0.5rem;
			justify-content: center;
			align-items: center;
			border-radius: 0.5rem;
		}
	}

	.card-style-grid-true {
		display: flex;
		padding: 0.5rem;
		gap: 1rem;
		justify-content: center;
		align-items: center;
		border-radius: 1rem;
		border-width: 4px;
		width: 100%;
		cursor: pointer;

		.ant-image .image {
			flex-shrink: 0;
			width: 3.5rem;
			padding: 8px 0px 8px 8px;
		}

		.image-not-found {
			border-top-left-radius: 0.5rem;
			border-bottom-left-radius: 0.5rem;
			width: 5rem;
			height: 5rem;
			border-radius: 0.5rem;
		}

		.card-content {
			padding: 0.5rem;
			flex: 1 1 0%;
			border-radius: 1rem;
		}

		.card-title {
			font-size: 1rem;
			line-height: 1.25rem;
			display: -webkit-box;
			-webkit-line-clamp: 1;
			-webkit-box-orient: vertical;
			overflow: hidden;
			font-weight: 600;
			color: #6b7280;
		}

		.question-count-container {
			display: flex;
			gap: 0.75rem;
			justify-content: flex-start;
			font-size: 0.875rem;
			line-height: 1.25rem;
			font-weight: 600;
			color: #9ca3af;
		}

		.question-count {
			font-size: 0.75rem;
			line-height: 1rem;
			font-weight: 600;
			color: #9ca3af;
		}

		.select-button {
			display: flex;
			padding-top: 0.25rem;
			padding-bottom: 0.25rem;
			padding-left: 0.25rem;
			padding-right: 0.25rem;
			gap: 0.25rem;
			justify-content: center;
			align-items: center;
			border-radius: 0.5rem;
		}
	}

	.spinner {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 10rem;
	}
}

.survey-open-text-item {
	display: flex;
	padding-right: 1rem;
	padding-left: 2.5rem;
	margin-top: 0.75rem;
	margin-bottom: 0.75rem;
	justify-content: space-between;
	align-items: center;
	border-radius: 0.5rem;
	border-width: 1px;
	border-color: #e5e7eb;
	height: 4rem;
	background-color: #ffffff;

	.input {
		border-radius: 0.5rem;
		border-color: #d1d5db;
		height: 2rem;
		font-size: 1rem;
		line-height: 1.5rem;
		color: #000000;
		cursor: text;
		width: 500px;
	}

	.option-container {
		display: flex;
		gap: 0.75rem;
	}
}

.survey-popup-form {
	.image-upload {
		display: flex;
		justify-content: start;
		height: 100%;
		width: 100%;
		gap: 0.5rem;

		.image-upload-label {
			position: relative;
			cursor: pointer;
			min-height: 154px;
			min-width: 154px;

			.image-preview {
				min-height: 154px;
				min-width: 154px;

				.image-hover {
					width: 100%;
					height: 100%;
					display: flex;
					position: absolute;
					justify-content: center;
					align-items: center;
					border-radius: 0.5rem;
					background-color: #00000075;
					transition-property: opacity;
					transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
					transition-duration: 300ms;
					transition-duration: 300ms;

					.text {
						color: white;
						font-size: 1.125rem;
					}
				}

				.image {
					height: 154px;
					width: 154px;
					border-radius: 0.5rem;
					padding: 8px 0px 8px 8px;
				}
			}

			.upload-placeholder {
				display: flex;
				width: 154px;
				height: 154px;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				border-radius: 0.5rem;
				border-width: 4px;
				border-color: #e5e7eb;
				border-style: dashed;
				font-size: 1.125rem;
				line-height: 1.75rem;
				font-weight: 600;
				color: #d1d5db;

				.image-hover {
					width: 154px;
					height: 154px;
					display: flex;
					position: absolute;
					justify-content: center;
					align-items: center;
					border-radius: 0.5rem;
					background-color: #00000075;
					--bg-opacity: 0.5;
					transition-property: opacity;
					transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
					transition-duration: 300ms;
					transition-duration: 300ms;

					.text {
						font-size: 1.125rem;
						line-height: 1.75rem;
						color: #ffffff;
					}
				}

				.upload-instructions {
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;
				}
			}
		}
	}

	.survey-title {
		height: 45px;
		width: 100%;
	}

	.textarea {
		width: 100%;
		margin-top: 0.75rem;
	}

	.question-section {
		display: flex;
		gap: 0.5rem;

		.question-input {
			cursor: pointer;
			border: 1px solid #d1d5db;
			width: 668px;
			border-radius: 0.5rem;
			height: 2.75rem;
			font-size: 1rem;
		}

		.select-container {
			display: flex;
			justify-content: center;

			.select-wrapper {
				display: flex;
				flex-direction: column;

				.select-row {
					display: flex;
					gap: 0.5rem;

					.score-btn {
						border: 1px solid #d1d5db;
						border-radius: 8px;
						display: flex;
						padding: 10px;
						cursor: pointer;
					}

					.active {
						background-color: #12b76a;
						color: #fff;
					}

					.score-btn:hover {
						background-color: #7f56d9;
						color: #fff;
					}

					.question-type-select {
						border-radius: 0.5rem;
						border-width: 1px;
						background-color: #ffffff;
						height: 45px;
						border-color: #d9d9d9;
					}

					.question-type-select:hover {
						border-color: rgb(127 86 217);
					}

					.question-type-select:focus {
						box-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
						--tw-ring-opacity: 1;
						--tw-ring-color: rgb(233 215 254);

						.icon {
							width: 1.25rem;
							height: 1.25rem;
							color: #d1d5db;
						}
					}

					.button-icon {
						margin-right: 0.5rem;
						width: 1.25rem;
						height: 1.25rem;
						color: #ffffff;
					}
				}
			}
		}
	}
}

.survey-question-item {
	.survey-panel {
		.question-panel {
			margin-top: 0.75rem;
			margin-bottom: 0.75rem;
			border-radius: 0.5rem;
			border-width: 1px;
			border-color: #e5e7eb;
			border-style: solid;
			background-color: #ffffff;

			.question-panel-header {
				display: flex;
				align-items: center;
				justify-content: start;
				width: 98%;

				.question-input {
					border-radius: 0.5rem;
					border-color: #d1d5db;
					height: 2.75rem;
					font-size: 1rem;
					line-height: 1.5rem;
					cursor: text;
					width: 90%;
				}
			}

			.extra-options {
				display: flex;
				gap: 0.75rem;
				margin-top: 0.25rem;
				margin-left: -0.75rem;
			}
		}
	}
}

.survey-question-option-item {
	display: flex;
	height: 3.5rem;
	margin-bottom: 1rem;
	border-radius: 0.5rem;
	padding: 0.25rem;
	padding-right: 1rem;
	gap: 0.5rem;

	.drag-handle {
		text-align: center;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 3.5rem;
		border-top-left-radius: 0.5rem;
		border-bottom-left-radius: 0.5rem;
		margin-top: -0.3125rem;
		width: 10px;
	}

	.option-content {
		background-color: #f9fafb;
		height: 2.75rem;
		display: flex;
		align-items: center;
		width: 90%;
		margin-left: 0.5rem;

		.input-title {
			cursor: text;
			width: 100%;
			border: 1px solid #d1d5db;
			border-radius: 0.5rem;
			height: 2.75rem;
			font-size: 1rem;
		}
	}

	.input-score {
		cursor: text;
		width: 10%;
		border: 1px solid #d1d5db;
		border-radius: 0.5rem;
		height: 2.75rem;
		font-size: 1rem;
	}

	.score-display {
		margin-top: 0.75rem;
		background-color: #7f56d9;
		display: flex;
		color: #ffffff;
		justify-content: center;
		align-items: center;
		width: 1.5rem;
		border-radius: 50%;
		height: 1.5rem;
	}

	.save-button {
		margin-top: 0.625rem;
	}

	.edit-button {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.delete-button {
		background-color: #f9fafb;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 2.5rem;
		cursor: pointer;
		z-index: 1000;
	}
}

.survey-rate-data-item {
	background-color: #f9fafb;
	padding: 0.5rem;

	.rate-section {
		// padding: 1.25rem;

		.rate-items {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;

			.rate-item {
				display: flex;
				height: 5rem;
				align-items: end;
				gap: 1rem;
				width: 100%;

				.input {
					cursor: text;
					width: 100%;
					border: 1px solid #d1d5db;
					border-radius: 0.5rem;
					height: 2.5rem;
					font-size: 1rem;
					color: #4b5563;
				}

				.display-input {
					width: 100%;
					padding-left: 0.5rem;
					border: 1px solid #d1d5db;
					background-color: #ffffff;
					border-radius: 0.5rem;
					height: 2.5rem;
					font-size: 1rem;
					display: flex;
					align-items: center;
					justify-content: flex-start;
					cursor: default;
				}

				.score-input {
					width: 20%;
				}

				.score-display {
					width: 20%;
					padding-left: 0.5rem;
				}
			}

			.delete-rate-item {
				margin-bottom: 6px;
				padding: 5px;
				border-radius: 9999px;
				border: 1px solid #ffe3e300;
			}
		}

		.action-button {
			margin-top: 0.625rem;
			display: flex;
			justify-content: flex-end;

			.button-content {
				display: flex;
				align-items: center;
				justify-content: center;
				gap: 8px;
			}
		}
	}

	.add-answer-section {
		display: flex;
		gap: 0.5rem;
		margin-bottom: 0.5rem;
		align-items: end;

		.answer-input {
			cursor: pointer;
			border: 1px solid #d1d5db;
			width: 38rem;
			border-radius: 0.5rem;
			height: 2.75rem;
			font-size: 1rem;
			color: #000000;
			width: 100%;
		}

		.title-section {
			display: flex;
			flex-direction: column;
			width: 100%;
		}

		.score-section {
			display: flex;
			flex-direction: column;

			.score-input-section {
				display: flex;
				flex-direction: row;
				gap: 0.5rem;

				.score-input {
					width: 11.5rem;
					height: 2.8125rem;
					background-color: #ffffff;
					border: 1px solid #d9d9d9;
					border-radius: 0.5rem;
					cursor: pointer;

					&:hover {
						border-color: #2563eb;
					}

					&:focus {
						border-color: #2563eb;
						outline: none;
						box-shadow: 0 0 0 1px #2563eb;
					}
				}

				.button-content {
					display: flex;
					align-items: center;
					justify-content: center;

					.add-icon {
						width: 1.25rem;
						height: 1.25rem;
						color: #ffffff;
						margin-right: 0.5rem;
					}
				}
			}
		}
	}
}

.system-template-survey {
	.search-input {
		margin-top: 0.75rem;
		margin-bottom: 0.75rem;
		width: 100%;
		height: 2.5rem;
	}

	.card-style {
		display: flex;
		margin-bottom: 0.75rem;
		gap: 0.5rem;
		justify-content: flex-end;
		align-items: center;
	}

	.card-style-div {
		display: flex;
		justify-content: flex-end;
	}

	.button-style-grid-true {
		padding: 0.25rem;
		border-top-right-radius: 0.5rem;
		border-bottom-right-radius: 0.5rem;
		border-width: 1px;
		border-left-width: 0;
		border-color: #d1d5db;
		text-align: center;
		cursor: pointer;
	}

	.button-style-grid-false {
		padding: 0.25rem;
		border-top-left-radius: 0.5rem;
		border-bottom-left-radius: 0.5rem;
		border-width: 1px;
		border-color: #d1d5db;
		text-align: center;
		cursor: pointer;
	}

	.card-style-grid-false {
		display: flex;
		padding: 1rem;
		gap: 1rem;
		border-radius: 0.5rem;
		border-width: 4px;
		width: 100%;
		cursor: pointer;

		.image {
			display: flex;
			padding: 0.5rem;
			width: 10rem;
			padding: 8px 0px 8px 8px;
		}

		.image-not-found {
			border-top-left-radius: 0.5rem;
			border-bottom-left-radius: 0.5rem;
			width: 100%;
			height: 9rem;
			border-radius: 0.5rem;
		}

		.card-content {
			padding: 0.5rem;
			flex: 1 1 0%;
			border-radius: 0.5rem;
		}

		.card-content-header {
			display: flex;
			justify-content: center;
			align-items: center;
		}

		.card-title {
			font-size: 1.125rem;
			line-height: 1.75rem;
			font-weight: 600;
			color: #6b7280;
		}

		.question-count {
			font-size: 0.75rem;
			line-height: 1rem;
			font-weight: 600;
			color: #9ca3af;
		}

		.card-description {
			margin-top: 0.5rem;
			color: #6b7280;
		}

		.select-button {
			display: flex;
			padding-top: 0.5rem;
			padding-bottom: 0.5rem;
			padding-left: 0.5rem;
			padding-right: 0.5rem;
			gap: 0.5rem;
			justify-content: center;
			align-items: center;
			border-radius: 0.5rem;
		}
	}

	.card-style-grid-true {
		display: flex;
		padding: 0.5rem;
		gap: 1rem;
		justify-content: center;
		align-items: center;
		border-radius: 1rem;
		border-width: 4px;
		width: 100%;
		cursor: pointer;

		.ant-image .image {
			flex-shrink: 0;
			width: 3.5rem;
			padding: 8px 0px 8px 8px;
		}

		.image-not-found {
			border-top-left-radius: 0.5rem;
			border-bottom-left-radius: 0.5rem;
			width: 5rem;
			height: 5rem;
			border-radius: 0.5rem;
		}

		.card-content {
			padding: 0.5rem;
			flex: 1 1 0%;
			border-radius: 1rem;
		}

		.card-title {
			font-size: 1rem;
			line-height: 1.25rem;
			display: -webkit-box;
			-webkit-line-clamp: 1;
			-webkit-box-orient: vertical;
			overflow: hidden;
			font-weight: 600;
			color: #6b7280;
		}

		.question-count-container {
			display: flex;
			gap: 0.75rem;
			justify-content: flex-start;
			font-size: 0.875rem;
			line-height: 1.25rem;
			font-weight: 600;
			color: #9ca3af;
		}

		.question-count {
			font-size: 0.75rem;
			line-height: 1rem;
			font-weight: 600;
			color: #9ca3af;
		}

		.select-button {
			display: flex;
			padding-top: 0.25rem;
			padding-bottom: 0.25rem;
			padding-left: 0.25rem;
			padding-right: 0.25rem;
			gap: 0.25rem;
			justify-content: center;
			align-items: center;
			border-radius: 0.5rem;
		}
	}

	.empty {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 10rem;
	}

	.pagination {
		display: flex;
		justify-content: center;
	}
}

.template-survey {
	.template-survey-header-container {
		display: flex;
		align-items: center;
		gap: 12px;

		.template-survey-select {
			background-color: #ffffff;
			height: 35px;
			width: 250px;
			border-color: #d9d9d9;
		}

		.template-survey-select:hover {
			border-color: rgb(127 86 217);
		}

		.template-survey-select:focus {
			box-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
			--tw-ring-opacity: 1;
			--tw-ring-color: rgb(233 215 254);

			.icon {
				width: 1.25rem;
				height: 1.25rem;
				color: #d1d5db;
			}
		}

		.search-input {
			margin-top: 0.75rem;
			margin-bottom: 0.75rem;
			width: 100%;
			height: 2.5rem;
		}

		.card-style {
			display: flex;
			gap: 0.5rem;
			justify-content: flex-end;
			align-items: center;
		}

		.card-style-div {
			display: flex;
			justify-content: flex-end;
		}

		.button-style-grid-true {
			padding: 0.25rem;
			text-align: center;
			cursor: pointer;
		}

		.button-style-grid-false {
			padding: 0.25rem;
			text-align: center;
			cursor: pointer;
		}
	}

	.spinner {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.card-style-grid-false {
		display: flex;
		gap: 1rem;
		border-radius: 0.5rem;
		border-width: 4px;
		width: 100%;
		cursor: pointer;

		.image {
			display: flex;
			width: 10rem;
			padding: 8px 0px 8px 8px;
		}

		.image-not-found {
			border-top-left-radius: 4px;
			border-bottom-left-radius: 4px;
			width: 100%;
			height: 9rem;
			border-radius: 0.5rem;
		}

		.card-content {
			padding: 1rem;
			flex: 1 1 0%;
			border-radius: 0.5rem;
		}

		.card-content-header {
			display: flex;
			justify-content: space-between;
			align-items: center;
		}

		.card-title {
			font-size: 1.125rem;
			line-height: 1.75rem;
			font-weight: 600;
			color: #6b7280;
		}

		.question-count {
			font-size: 0.75rem;
			line-height: 1rem;
			font-weight: 600;
			color: #9ca3af;
		}

		.card-description {
			margin-top: 0.5rem;
			color: #6b7280;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		.template-button-container {
			display: flex;
			align-items: center;
			justify-content: end;
			gap: 1rem;
			background-color: #f9f5ff;
			color: #6941c6;
			padding: 2px 8px;
			border-radius: 16px;
			width: max-content;
		}

		.select-button {
			display: flex;
			padding-top: 0.5rem;
			padding-bottom: 0.5rem;
			padding-left: 0.5rem;
			padding-right: 0.5rem;
			gap: 0.5rem;
			justify-content: center;
			align-items: center;
			border-radius: 0.5rem;
		}
	}

	.card-style-grid-false:hover {
		border-color: #12b76a;
	}

	.card-style-grid-true:hover {
		border-color: #12b76a;
	}

	.card-style-grid-true {
		display: flex;
		padding: 0.5rem;
		gap: 1rem;
		justify-content: center;
		align-items: center;
		border-radius: 1rem;
		border-width: 4px;
		width: 100%;
		cursor: pointer;
		flex-direction: column;
		height: 200px;

		.ant-image .image {
			flex-shrink: 0;
			width: 3.5rem;
			padding: 8px 0px 8px 8px;
		}

		.image-not-found {
			border-radius: 0.5rem;
			width: max-content;
			height: 4rem;
			border-radius: 0.5rem;
		}

		.card-content {
			padding: 0.5rem;
			flex: 1 1 0%;
			border-radius: 1rem;
			width: -webkit-fill-available;
		}

		.card-title {
			font-size: 1rem;
			line-height: 1.25rem;
			display: -webkit-box;
			-webkit-line-clamp: 1;
			-webkit-box-orient: vertical;
			overflow: hidden;
			font-weight: 600;
			color: #6b7280;
		}

		.question-count-container {
			display: flex;
			gap: 0.75rem;
			justify-content: flex-start;
			font-size: 0.875rem;
			line-height: 1.25rem;
			font-weight: 600;
			color: #9ca3af;
		}

		.question-count {
			font-size: 0.75rem;
			line-height: 1rem;
			font-weight: 600;
			color: #9ca3af;
		}

		.select-button {
			display: flex;
			padding-top: 0.25rem;
			padding-bottom: 0.25rem;
			padding-left: 0.25rem;
			padding-right: 0.25rem;
			gap: 0.25rem;
			justify-content: center;
			align-items: center;
			border-radius: 0.5rem;
		}
	}

	.empty {
		padding: 2.5rem;
		margin: 0;
		background-color: #f9fafb;
	}

	.pagination {
		display: flex;
		justify-content: center;
	}
}

.edit-survey-card {
	.action-button-container {
		visibility: hidden;
		display: flex;
	}

	.survey-card:hover .action-button-container {
		visibility: visible;
	}

	.survey-card {
		display: flex;
		gap: 1rem;
		border-radius: 0.75rem;
		border-width: 4px;
		background-color: #f9fafb;
		cursor: pointer;

		.image-container {
			display: flex;
			width: 9rem;
			align-items: center;
			padding: 8px 0px 8px 10px;

			.image-not-found {
				border-top-left-radius: 0.5rem;
				border-bottom-left-radius: 0.5rem;
				height: 9rem;
				border-radius: 0.5rem;
			}
		}

		.card-content {
			flex: 1 1 0%;
			border-radius: 0.5rem;
			padding: 0.5rem;

			.card-header {
				font-size: 1.125rem;
				line-height: 1.75rem;
				font-weight: 600;
				color: #6b7280;
				display: flex;
				gap: 8px;
				align-items: center;

				.survey-title {
					display: -webkit-box;
					-webkit-line-clamp: 1;
					-webkit-box-orient: vertical;
					overflow: hidden;
					text-overflow: ellipsis;
				}
			}

			.survey-question-count {
				font-size: 0.75rem;
				line-height: 1rem;
				font-weight: 600;
				color: #9ca3af;
			}

			.survey-description-container {
				margin-top: 0.5rem;
				color: #6b7280;
			}

			.active-button-container {
				display: flex;
				align-items: center;
				justify-content: end;
				gap: 1rem;
				background-color: #ecfdf3;
				color: #027a48;
				padding: 2px 8px;
				border-radius: 16px;
				width: max-content;
			}

			.non-active-button-container {
				display: flex;
				align-items: center;
				justify-content: end;
				gap: 1rem;
				background-color: #fdecea;
				color: #b42318;
				padding: 2px 8px;
				border-radius: 16px;
				width: max-content;
			}

			.template-button-container {
				display: flex;
				align-items: center;
				justify-content: end;
				gap: 1rem;
				background-color: #f9f5ff;
				color: #6941c6;
				padding: 2px 8px;
				border-radius: 16px;
				width: max-content;
			}

			.action-button-container {
				display: flex;
				align-items: center;
				justify-content: end;
				gap: 1rem;

				.edit-button {
					display: flex;
					padding-top: 0.5rem;
					padding-bottom: 0.125rem;
					padding-left: 0.375rem;
					padding-right: 0.375rem;
					padding-bottom: 0.25rem;
					gap: 0.25rem;
					align-items: center;
					border-radius: 0.5rem;
					height: fit-content;
					background-color: #f3f4f6;
				}

				.edit-button:hover svg {
					stroke: #7f56d9;
				}

				.delete-button:hover svg {
					stroke: #7f56d9;
				}

				.approve-button {
					display: flex;
					padding-left: 0.375rem;
					padding-right: 0.375rem;
					gap: 0.25rem;
					align-items: center;
					border-radius: 0.5rem;
					height: fit-content;
				}

				.approve-button:hover svg {
					stroke: #7f56d9;
				}

				.play-icon {
					display: inline-flex;
					align-items: center;
					justify-content: center;
					width: 36px;
					height: 36px;
					border-radius: 50%;
					background-color: #12b76a;
					transition: background-color 0.3s;
				}

				.play-icon:hover {
					background-color: #7f56d9;
				}
			}
		}
	}

	.survey-card:hover {
		border-color: #12b76a;
	}
}

.ai-assistant-start-survey {
	margin-right: 1.25rem;
	margin-left: 1.25rem;

	.ai-response {
		display: flex;
		margin-top: 1.25rem;
		gap: 9px;
		justify-content: flex-start;
		align-items: flex-start;

		.icon {
			padding-top: 0.25rem;
			padding-bottom: 0.25rem;
			padding-left: 0.375rem;
			padding-right: 0.375rem;
			border-radius: 9999px;
			background-color: rgb(244 235 255);
		}

		.content {
			width: 100%;

			.ai-assistant {
				font-weight: 700;
			}

			.description {
				color: rgb(102 112 133);
			}
		}
	}

	.create-survey-button {
		display: flex;
		margin-bottom: 0.5rem;
		margin-top: 1.5rem;
		justify-content: center;
		align-items: center;
		border-radius: 0.75rem;
		border-width: 2px;
		border-color: #d1d5db;
		border-style: dashed;
		width: 100%;
		height: 2.75rem;
		font-weight: 600;
		color: #9ca3af;
		cursor: pointer;
	}

	.spinner {
		display: flex;
		padding: 0.5rem;
		justify-content: center;
		align-items: center;
	}

	.empty {
		padding: 2.5rem;
		margin: 0;
		background-color: #f9fafb;
	}

	.card-list {
		display: grid;
		margin-top: 1rem;
		grid-template-columns: repeat(2, minmax(0, 1fr));
		gap: 1rem;
	}
}

.start-survey-user {
	padding-right: 1.25rem;
	padding-left: 1.25rem;

	.selected-survey-container {
		max-width: 900px;

		.selected-survey-container-animator {
			display: flex;
			flex-direction: column;
			transition-property: opacity;
			transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
			transition-duration: 300ms;
			transition-duration: 500ms;

			.survey-option-list-container {
				margin-top: 0.75rem;
				margin-left: 2.5rem;

				.selected-survey-title-container {
					display: flex;
					margin-top: 0.75rem;
					gap: 9px;
					justify-content: flex-start;
					align-items: center;

					.magic-wand {
						padding-top: 0.25rem;
						padding-bottom: 0.25rem;
						padding-left: 0.375rem;
						padding-right: 0.375rem;
						border-radius: 9999px;
						background-color: rgb(244 235 255);
					}

					.question-container {
						flex-direction: row;
					}

					.title {
						font-weight: 600;
						color: #374151;

						.question-type {
							margin-left: 0.5rem;
							color: #d1d5db;
						}
					}
				}
			}
		}
	}

	.survey-list-container {
		transition-property: opacity;
		transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
		transition-duration: 300ms;
		transition-duration: 500ms;

		.ai-response {
			display: flex;
			margin-top: 1.25rem;
			gap: 9px;
			justify-content: flex-start;
			align-items: flex-start;

			.icon {
				padding-top: 0.25rem;
				padding-bottom: 0.25rem;
				padding-left: 0.375rem;
				padding-right: 0.375rem;
				border-radius: 9999px;
				background-color: rgb(244 235 255);
			}

			.content {
				width: 100%;

				.ai-assistant {
					font-weight: 700;
				}

				.description {
					color: rgb(102 112 133);
				}
			}
		}

		.list-container-row {
			margin-top: 0.5rem;
			margin-left: 2rem;

			.list-container-col {
				display: flex;
				gap: 0.75rem;
				border-radius: 0.75rem;
				border-width: 4px;
				border-color: #e5e7eb;
				height: 100%;
				background-color: #f9fafb;

				.image-container {
					display: flex;
					width: 14rem;
					width: 12rem;
					align-items: center;
					padding: 8px 0px 8px 10px;

					.image-not-found {
						border-top-left-radius: 0.5rem;
						border-bottom-left-radius: 0.5rem;
						width: 100%;
						height: 8rem;
						border-radius: 0.5rem;
					}
				}

				.survey-content-container {
					display: flex;
					flex-direction: column;
					justify-content: space-between;
					width: 100%;
					height: 100%;
					padding: 1rem;

					.survey-title {
						font-size: 1.125rem;
						line-height: 1.75rem;
						color: #374151;

						overflow: hidden;
						display: -webkit-box;
						-webkit-box-orient: vertical;
						-webkit-line-clamp: 1;
					}

					.survey-question-count {
						color: #9ca3af;
					}

					.survey-description {
						margin-top: 0.5rem;
						color: #6b7280;
						overflow: hidden;
						display: -webkit-box;
						-webkit-box-orient: vertical;
						-webkit-line-clamp: 2;
					}

					.start-survey-button {
						display: flex;
						margin-top: 0.5rem;
						justify-content: center;
						align-items: center;
						border-radius: 0.5rem;
						border-style: none;
						width: 100%;
					}

					.start-survey-button:hover {
						background-color: rgb(127 86 217);
					}

					.start-survey-button-icon {
						width: 1rem;
						height: 1rem;
						color: #533f85;
					}

					.non-finished-survey-css {
						background-color: #bdff00 !important;
					}
				}
			}
		}

		.empty {
			padding: 2.5rem;
			margin: 0;
		}
	}
}

.survey-option-list {
	margin-left: 0.5rem;

	.option-container {
		padding: 0.5rem;
		margin-top: 0.5rem;
		border-radius: 0.5rem;
		border-width: 1px;
		cursor: pointer;
	}

	.option-container:hover {
		background-color: #e9d7fe;
	}

	.rate-container {
		display: flex;
		margin-top: 0.75rem;
		margin-left: 2.5rem;
		border-radius: 0.75rem;
		border-width: 1px;
		width: fit-content;
		flex-direction: column;

		.options-row {
			display: flex;
			flex-direction: row;
		}

		.rate {
			display: flex;
			padding: 1.25rem;
			flex-direction: column;
			gap: 0.5rem;
			justify-content: center;
			align-items: center;
		}
	}

	.open-text {
		margin-top: 0.75rem;
		width: 100%;
	}

	.ai-response {
		display: flex;
		margin-top: 1.25rem;
		gap: 9px;
		justify-content: flex-start;
		align-items: flex-start;

		.icon {
			padding-top: 0.25rem;
			padding-bottom: 0.25rem;
			padding-left: 0.375rem;
			padding-right: 0.375rem;
			border-radius: 9999px;
			background-color: rgb(244 235 255);
		}

		.content {
			width: 100%;

			.ai-assistant {
				font-weight: 700;
			}
		}
	}

	.tankyou-text {
		margin-left: 2.5rem;
		color: #6b7280;
	}

	.exit-button {
		display: flex;
		padding: 0.5rem;
		margin-top: 0.5rem;
		gap: 0.5rem;
		align-items: center;
		border-radius: 0.5rem;
		width: fit-content;
		background-color: #ffffff;
		cursor: pointer;
		color: rgb(158 119 237);
		border: 1px solid rgb(127 86 217);
	}

	.button-activated {
		padding: 0.5rem;
		margin-top: 0.5rem;
		border-radius: 0.5rem;
		width: fit-content;
		color: #ffffff;
		cursor: pointer;
		background-color: rgb(127 86 217);
	}

	.button-faded {
		padding: 0.5rem;
		margin-top: 0.5rem;
		border-radius: 0.5rem;
		width: fit-content;
		color: #ffffff;
		cursor: pointer;
		background-color: rgb(214 187 251);
	}
}
