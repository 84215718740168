@import './style.less';
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;600;700&display=swap');

/* overwrite antdesign default fonts */
body {
	font-family: 'Inter', 'Helvetica Neue', Arial, Helvetica, sans-serif;
}

#root, .ant-modal-wrap {
	div, span, p, ul, li, button, strong , ol {
		font-family: 'Inter', 'Helvetica Neue', Arial, Helvetica, sans-serif ;
	}
}

html, body, #root{
	height: 100%;
	background: #f5f5f5;
}

@media screen and (orientation: portrait) {
  html {
    transform: rotate(-90deg);
    transform-origin: left top;
    width: 100vh;
    height: 100vw;
    overflow-x: hidden;
    position: fixed;
    top: 100%;
    left: 0;
  }

  body {
    overflow: auto;
  }

  .admin-menu-content {
      height: 100vw;
  }
}

.layout{
	background-color: rgb(255, 255, 255);
	flex-wrap: wrap;
	justify-content: space-between;
	overflow: auto;
	height: 100%;
}

.default-layout-admin > .content {
	height: 100vh;
	overflow-x: hidden;
	overflow-y: auto;
}

.default-layout-admin .sidebar-admin {
	height: 100vh;
	overflow-x: hidden;
	overflow-y: auto;
}

.ui.form textarea,
.ui.form input:not([type]),
.ui.form input[type='date'],
.ui.form input[type='datetime-local'],
.ui.form input[type='email'],
.ui.form input[type='file'],
.ui.form input[type='number'],
.ui.form input[type='password'],
.ui.form input[type='search'],
.ui.form input[type='tel'],
.ui.form input[type='text'],
.ui.form input[type='time'],
.ui.form input[type='url'] {
	background: #f5f5f7 !important;
	border-color: white !important;
}

.ui.icon.button > .icon,
.ui.icon.buttons .button > .icon {
	margin: 0px 0 0 1px !important;
}

.alink {
	color: #4183c4;
	text-decoration: none;
	border: 0;
	background: transparent;
	cursor: pointer;
	box-sizing: inherit;
}

.hotspot-circle.active:after {
	top: -2px !important;
	left: -2px !important;
}

.custom-ant-table .ant-table-thead > tr > th {
	text-transform: uppercase;
	font-size: 0.8em;
	color: rgba(0, 0, 0, 0.255);
	font-weight: 700;
}

.custom-ant-table .ant-table-thead > tr > th,
.custom-ant-table .ant-table-tbody > tr > td,
.custom-ant-table .ant-table tfoot > tr > th,
.custom-ant-table .ant-table tfoot > tr > td {
	padding: 12px 16px;
}

h1.custom-title {
	font-size: 16px;
	font-weight: 700;
	margin: 40px 0 10px 0;
}

h1.custom-subtitle {
	font-size: 14px;
	font-weight: 700;
	margin: 20px 0 0;
}

.carespace-coach .instruction-video {
	width: 100%;
}

.btn-outline.btn-close,
.btn-outline.btn-close-compare {
	padding: 5px !important;
}

.pre-assessment .container {
	margin-top: 20px;
}

.video-item video.video {
	max-width: 300px;
}

.tag-pt,
.tag-user {
	padding: 2px 6px;
	position: absolute;
	top: 5px;
	color: #fff;
	border-radius: 7px;
	right: 14px;
	font-size: 13px;
	font-weight: 700;
}

.tag-pt {
	background: #6b2ba0;
}

.tag-user {
	background: #01c752;
}

button.ant-btn.ant-btn-default.btn-purple {
	background: #6739b7;
	font-size: 14px;
	font-weight: 600;
	text-transform: uppercase;
	color: #fff;
	margin: 8px 0;
	border-radius: 6px;
	border: 2px solid #6739b7;
	cursor: pointer;
}

button.ant-btn.ant-btn-default.btn-purple[disabled] {
	background: #4d346c75;
	color: #fcfbfe2b;
	font-size: 14px;
	font-weight: 600;
	text-transform: uppercase;
	margin: 8px 0;
	border-radius: 6px;
	border: 2px solid #6739b7;
	cursor: default;
}

.record-video-btn.btn-purple {
	float: right;
}

button.ant-btn.ant-btn-default.btn-purple.record-video-btn:hover {
	background: transparent;
	color: #6739b7;
	border-color: #6739b7;
}

.carespace-coach .ant-breadcrumb ol {
	justify-content: center;
}

.carespace-coach span.ant-breadcrumb-link {
	color: #fff;
}
.ant-carousel .slick-prev,
.ant-carousel .slick-next,
.ant-carousel .slick-prev:hover,
.ant-carousel .slick-next:hover {
	font-size: inherit;
	color: currentColor;
}
.ant-carousel .slick-prev,
.ant-carousel .slick-next {
	padding: 5px 0;
	margin-top: -5px;
}
@media screen and (min-width: 3000px) {
	.carespace-body {
		zoom: 250%;
	}
}

.dropdown-badge button {
	position: relative;
	top: 2px;
}

.ant-select-dropdown{
	width: auto !important;
}

.rc-virtual-list-holder-inner {
  position: relative !important;
}

.ant-card-small >.ant-card-body {
	padding: 0px;
}
.ant-message .ant-message-notice-wrapper .ant-message-notice-content{

	color: #FFFFFF;
	padding:20px;
	font-size: 18px;
}
.ant-message .ant-message-notice-wrapper .ant-message-notice-success .ant-message-notice-content {
	background: #01c752;
}
.ant-message .ant-message-notice-wrapper .ant-message-notice-error .ant-message-notice-content {
	background: #E31B54;
}
.ant-message .ant-message-notice-wrapper .ant-message-notice-info .ant-message-notice-content {
	background: #1677FF;
}
.ant-message .ant-message-notice-wrapper .ant-message-notice-warning .ant-message-notice-content {
	background: #faad14;
}
.ant-message .ant-message-notice-wrapper .ant-message-notice-loading .ant-message-notice-content {
	background: #faad14;
}
.ant-message .ant-message-notice-wrapper .ant-message-notice-content .anticon{
	color: #FFFFFF;
	font-size: 21px;
}

