.block {
	display: block;
}
.text-base {
	font-size: 0.65;
  line-height: 1.5rem;
  font-weight: 600;
}
.text-black-700 {
	color: #2d3748;
}
.w-10 {
	width: 2.5rem;
}
.h-10 {
	height: 2.5rem;
}
.leading-10 {
	line-height: 2.5rem;
}
.rounded-full {
	border-radius: 9999px;
}
.mt-auto {
	margin-top: auto;
}
.z-10 {
	z-index: 10;
}
.cursor-pointer {
	cursor: pointer;
}
.w-11 {
	width: 2.75rem;
}
.text-primary-700 {
	color: rgb(105 65 198 / var(--tw-text-opacity));
}
.text-grey-500 {
	color: #a0aec0;
}
.bg-gray-300 {
	background-color: #e2e8f0;
}
.text-light-grey-500 {
	color: #a0aec0;
}
.text-gray-300 {
	color: #e2e8f0;
}
.bg-primary-300 {
	--tw-bg-opacity: 1;
	background-color: rgb(214 187 251 / var(--tw-bg-opacity));
}
.bg-gray-200 {
	background-color: #edf2f7;
}
.bg-primary-700 {
	--tw-bg-opacity: 1;
	background-color: rgb(105 65 198 / var(--tw-bg-opacity));
}
.text-white {
	color: #fff;
}
.bg-primary-200 {
	--tw-bg-opacity: 1;
	background-color: rgb(233 215 254 / var(--tw-bg-opacity));
}
.grid {
	display: grid;
}
.grid-cols-7 {
	grid-template-columns: repeat(7, 1fr);
}
.gap-3 {
	gap: 0.75rem;
}
