.admin-menu-main-container {
	position: relative;
}

.admin-menu {
	position: absolute;
	margin-top: 0.5rem;
	left: 22px;
}

.logo {
	cursor: pointer;
	margin-bottom: 1rem;
	margin-top: 0.75rem;
	margin-left: 1rem;
}

.admin-profile {
	position: absolute;
	bottom: 0;
	left: 22px;
}

.menu-item {
	cursor: pointer;
	margin-bottom: 1rem;
	margin-top: 0.75rem;
	margin-left: 1rem;
	padding: 0.5rem;
	text-align: center;
	border-radius: 0.375rem;
	width: 2.5rem;
}

.menu-item:hover {
	background-color: #7f56d9;
}

.profile-item {
	cursor: pointer;
	margin-bottom: 1rem;
	margin-top: 0.75rem;
	margin-left: 1rem;
	padding: 0.5rem;
	text-align: center;
	border-radius: 0.375rem;
	width: 2.5rem;
}

.profile-item:hover {
	background-color: #7f56d9;
}

.menu-item-active {
	background-color: #7f56d9;
}

.popup-label-capitalize {
	text-transform: capitalize;
}

.admin-menu-content {
	padding: 16px;
	background-color: #53389e;
	margin-left: 6rem;
	height: 100vh;
	width: 20rem;
	display: grid;
	align-items: stretch;
	overflow-y: auto;
	overflow-x: hidden;
}

.submenu-title {
	font-size: 1.125rem;
	color: white;
	margin-bottom: 1.25rem;
}

.submenu-list {
	margin-top: 0.75rem;
}

.submenu-item {
	cursor: pointer;
	border-radius: 0.375rem;
	font-size: 1rem;
	margin-bottom: 0.25rem;
	padding-top: 0.25rem;
	padding-bottom: 0.25rem;
	line-height: 1.5rem;
}

.submenu-item-active,
.submenu-item:hover {
	background-color: #7f56d9;
}

.icon-container {
	display: inline-block;
	vertical-align: middle;
	margin-left: 0.5rem;
	padding-right: 0.25rem;
	padding-left: 0.25rem;
}

.label-container {
	display: inline-block;
	font-weight: 600;
	font-size: 1rem;
	vertical-align: middle;
	margin-left: 0.75rem;
	padding-top: 0.25rem;
	padding-bottom: 0.25rem;
	padding-right: 0.25rem;
	padding-left: 0.25rem;
}

.status-count {
	display: inline-block;
	margin-left: 0.75rem;
	margin-right: 0.25rem;
	margin-top: 0.25rem;
	width: 1.75rem;
	height: 1.5rem;
	border-radius: 9999px;
	background-color: #6941c6;
	color: white;
	text-align: center;
	font-size: 0.75rem;
	line-height: 1.5rem;
	float: right;
}

.status-count:hover {
	background-color: #6941c6;
}

.user-info-list {
	margin-top: 20px;
	align-self: end;
}

.user-info-item {
	background-color: rgb(83 56 158 / var(--tw-bg-opacity));
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.user-details {
	display: inline-block;
	vertical-align: middle;
	width: 80%;
	font-size: 0.875rem;
	line-height: 1.25rem;
	background-color: rgb(83 56 158 / var(--tw-bg-opacity));
}

.user-name-label {
	font-weight: 500;
}

.user-email-label {
	font-weight: 400;
}

.logout-button {
	background-color: transparent !important;
	border: transparent !important;
	padding: 0 !important;
}

.logout-button:hover {
	background-color: #7f56d9 !important;
}

.admin-menu-contacts-data {
	text-align: right;
	display: block !important;
}

.admin-menu-contacts-data h3 {
	font-size: 1.125rem;
	color: white;
}

.select-container {
	color: black;
}

.user-main-container {
	position: relative;
}

.user-container {
	display: flex;
	flex-direction: column;
	margin-top: 1.25rem;
	margin-bottom: 0.5rem;
	align-items: center;
	justify-content: center;
}

.edit-container {
	position: absolute;
	left: 63%;
	top: 2.5rem;
	border-radius: 9999px;
	cursor: pointer;
}

.user-name-contact {
	font-family: 'Inter', sans-serif;
	font-weight: 600;
	margin-top: 0.5rem;
	font-size: 1.25rem;
	text-align: center;
}

.user-email-contact {
	color: rgb(152 162 179 / var(--tw-text-opacity));
	margin-bottom: 0.5rem;
	white-space: normal;
	word-break: break-all;
}

.activity-container {
	text-align: right;
	display: block !important;
}

.flex-container-message {
	margin-top: 12px;
}

.flex-container-message h3 {
	color: white;
	font-size: 1.125rem;
}

.scroll-messagebox {
	overflow-y: auto;
	overflow-x: hidden;
	margin-top: 12px;
}

.inbox-item-info {
	width: 66.66%;
	display: flex;
	flex-direction: column;
}

.name-label {
	font-size: 0.875rem;
	line-height: 1.25rem;
	font-weight: 600;
	font-family: 'Inter';
}

.email-label {
	font-size: 0.875rem;
	line-height: 1.25rem;
	font-weight: 400;
	font-family: 'Inter';
	text-align: left;
}

.inbox-item-time {
	width: 30%;
	display: flex;
	flex-direction: column;
}

.inbox-item-unread {
	display: inline-block;
	margin-left: 0.75rem;
	margin-right: 0.25rem;
	margin-top: 0.25rem;
	width: 1.5rem;
	height: 1.5rem;
	border-radius: 9999px;
	text-align: center;
	font-size: 0.75rem;
	line-height: 1.5rem;
	font-weight: 500;
	font-family: 'Inter';
	background-color: rgba(214, 187, 251, 1);
	color: rgba(83, 56, 158, 1);
	float: right;
}

.empty-container {
	display: flex;
	align-items: center;
	justify-content: center;
	color: white;
}

.border-primary-300 {
	border-color: rgb(214 187 251 / var(--tw-border-opacity));
}

.popover-content-div {
	color: rgb(255 255 255 / var(--tw-text-opacity));
	user-select: none;
	margin-top: 4px;
	margin-bottom: 4px;
	display: grid;
  grid-template-columns: repeat(4, 1fr); 
  gap: 8px;
  justify-content: center;
  align-items: center;
	padding: 8px;
}

.functional-goal-css {
	border-radius: 9999px;
	background-color: white;
	color: black;
	height: 0.5rem;
	width: 0.5rem;
	margin-top: 8px;
	display: flex;
  justify-content: center;
  align-items: center;
}

.user-details-main-container {
	display: flex;
	flex-direction: column;
}

.user-details-sub-container {
	display: flex;
	flex-direction: column;
	padding: 1rem;
	gap: 0.5rem;
	border-radius: 0.5rem;
	background-color: rgba(92, 62, 170, 1);
	margin-top: 4px;
	margin-bottom: 4px;
}

.user-form-titles {
	font-family: 'Inter';
	font-weight: 400;
	font-size: 0.75rem;
	line-height: 1.25rem;
}

.user-form-item {
	font-family: 'Inter';
	font-weight: 600;
	font-size: 14px;
	color: white;
}

.user-form-item-result {
	font-family: 'Inter';
	color: #98a2b3;
	font-size: 12px;
	align-items: center;
	font-weight: 400;
}

.user-between-container {
	display: flex;
	gap: 40px;
	margin-top: 8px;
	justify-content: space-around;
}

.user-between-container li {
	display: flex;
	flex-direction: column;
	text-align: center;
	align-items: center;
	justify-content: center;
}

.valid-birthdate-css {
	margin-top: -18px;
}

.non-valid-birthdate-css {
	margin-top: -8px;
	margin-left: 40px;
}

.functional-button-css {
	background-color: #7f56d9 !important;
	width: 100% !important;
	height: 2.5rem !important;
	color: white !important;
	border-radius: 0.5rem !important;
}

.functional-button-css:hover {
	border-color: rgb(255 255 255 / var(--tw-border-opacity)) !important;
}

.functional-button-div {
	display: flex;
	padding: 0.25rem;
	margin-top: 12px;
	align-items: center;
}

.functional-title {
	font-size: 0.875rem;
	font-weight: 600;
	margin-left: 0.5rem;
	margin-top: -2px;
	line-height: 1.25rem;
}

.wellness-button-css {
	color: white;
	width: 90%;
	height: 36px;
	border: none;
	border-radius: 20px;
}

.function-goal-icon-css {
	width: 3.5rem;
	height: 3.5rem;
	border-radius: 9999px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	transition: all;
	padding: 8px;
}

.function-goal-icon-css:hover {
  background-color: #6d41d5; 
	opacity: 1;
}

.function-goal-icon-selected {
	background-color: #53389e ;
}

.function-goal-icon-non-selected {
	background-color: #6541B7;
	opacity: 0.5;
}

.user-container .start-session-css {
	background-color: #bdff00 !important;
}
