.aitab-menu-container {
	display: flex;
	align-items: center;
	gap: 16px;
	padding: 12px;
	background: linear-gradient(270deg,
			#6b35e0,
			#633ebb,
			#647ad4,
			#5196ec,
			#6c47c9);
	background-size: 400% 400%;
	-webkit-animation: BgAnimaiton 18s ease infinite;
	-moz-animation: BgAnimaiton 18s ease infinite;
	animation: BgAnimaiton 18s ease infinite;

	.aitab-menu-title {
		color: white;
		font-size: 14px;
		font-weight: bold;
	}

	.aitab-menu-item {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 8px;
		padding-left: 8px;
		padding-right: 8px;
		border-radius: 6px;
		color: #ffffff55;
		cursor: pointer;
	}

	.selected-ai-tab {

		.stroke-white,
		.text-\[\#ffffff55\] {
			opacity: 100;
			color: white !important;
			stroke: white !important;
		}
	}

	.selected-ai-tab {
		border: 1px solid #f9fafb;
		padding: 1px;
		padding-left: 8px;
		padding-right: 8px;
		border-radius: 8px;
		color: white;
	}

	.unselected-ai-tab:hover {
		background-color: #7f56d9;
		color: white;

		.stroke-white,
		.text-\[\#ffffff55\] {
			opacity: 100;
			color: white !important;
			stroke: white !important;
		}
	}

	.aitab-menu-divider {
		color: #ffffff55;
	}
}
