.image-navigation-container {
	display: flex;
	align-items: center;
	position: relative;
	clear: both;
}

.horizontal-scroll-container {
	display: flex;
	overflow-x: auto;
	gap: 16px;
	-ms-overflow-style: none;
	scrollbar-width: none;
}

.horizontal-scroll-container::-webkit-scrollbar {
	display: none;
}

.custom-image-container-true-form {
	width: 250px;
	height: 250px;
	flex-shrink: 0;
	position: relative;
	transition: all 0.3s ease-in-out;
	cursor: pointer;
}

.custom-image-container-true-form.active-image {
	width: 350px;
	height: 350px;
	transition: all 0.3s ease-in-out;
}

.image-wrapper-form {
	width: 100%;
	height: 100%;
	position: relative;
	overflow: hidden;
}

.image-wrapper-form img {
	border-radius: 0.5rem;
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.image-wrapper-form::after {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0);
	border-radius: 0.5rem;
	transition: background-color 0.3s;
}

.image-wrapper-form:hover::after {
	background-color: rgba(0, 0, 0, 0.2);
}

.overlay-content-css {
	position: absolute;
	inset: 0;
	background: rgba(0, 0, 0, 0.6);
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	padding: 1rem;
	height: 100%;
	border-radius: 8px;
	opacity: 1;
	transition: opacity 0.3s ease-in-out;
}

.custom-image-container-form:hover .overlay-content-css {
	opacity: 1;
}

.overlay-content-css .hover-details {
	opacity: 0;
	transition: opacity 0.3s ease-in-out;
}

.custom-image-container-form:hover .overlay-content-css .hover-details {
	opacity: 1;
}

.exercise-count {
	color: white;
	background-color: rgba(0, 0, 0, 0.7);
	padding: 0.5rem 1rem;
	font-size: 14px;
	border-radius: 24px;
	position: absolute;
	top: 5%;
	right: 5%;
	z-index: 20;
}

.exercise-count-duration {
	color: white;
	background-color: rgba(0, 0, 0, 0.7);
	padding: 0.5rem 1rem;
	font-size: 14px;
	border-radius: 24px;
	position: absolute;
	top: 5%;
	left: 5%;
	z-index: 20;
}

.last-session-data {
	color: white;
	padding: 0.5rem;
	font-size: 11px;
	border-radius: 8px;
	position: absolute;
	z-index: 20;
	width: 250px;
	bottom: 12%;
	left: 39%;
	transform: translateX(-50%);
}

.extra-details-div {
	display: flex;
	font-size: 11px;
	color: white;
	justify-content: flex-end;
}

.title-true-form {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	color: white;
	border-radius: 5px;
	opacity: 0;
	transition: opacity 0.3s ease-in-out;
	z-index: 10;
	cursor: pointer;
}

.custom-image-container-form:hover .title-true-form {
	opacity: 1;
}

.image-wrapper-form:hover .title-true-form {
	opacity: 1;
}

.navigation-buttons {
	position: absolute;
	top: 150px;
	left: 0;
	right: 0;
	transform: translateY(-50%);
	display: flex;
	justify-content: space-between;
	width: 100%;
	pointer-events: none;
	z-index: 100;
}

.custom-image-container-form {
	width: 300px;
	height: 300px;
	flex-shrink: 0;
	position: relative;
	cursor: pointer;
	border-radius: 8px;
	transition:
		width 0.3s ease,
		height 0.3s ease;
}

.image-wrapper-form video {
	pointer-events: auto;
}

.image-wrapper-form::after {
	pointer-events: none;
}

.custom-image-container-form.active-image {
	width: 330px;
	height: 330px;
}

.navigation-buttons span {
  pointer-events: auto;
  z-index: 100;
}

.custom-button-patient-dashboard {
	color: #533F85;
	border: inherit;
	width: 100%;
	height: 50px;
	margin-top: 6px;
	margin-bottom: 6px;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	overflow: hidden;
	white-space: normal;
	word-wrap: break-word;
}

.program-main-container-css {
  height: 410px;
	background-color: white;
	border-radius: 16px;
	padding: 1rem;
	margin-bottom: 1.25rem;
}

.main-heading-div-css{
	display: flex;
	flex-direction: column;
	padding-bottom: 1rem;
}